<template>
  <v-container mt-flex fluid>
  <v-container d-flex d-sm-none>
    <v-row>
      <v-col cols="12" class="pa-5">
        
      </v-col>
    </v-row>
    </v-container>
    <v-row >
      <v-col class="pa-5 bg-grey">
      <h1>Enrolment Terms and Conditions</h1>
      </v-col>
    </v-row>
    <v-row 
      v-for="items in appTexts.termsEnrolment"
      :key="items.title"
    >
      <v-col>
      <h2>
        {{ items.title }}
      </h2>
      <p>{{ items.para }}</p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>



export default {
  methods: {

    
      
  },

  computed: {

    
  },
}
</script>
<style>

.view-enter-active {
  animation-duration: 1.5s;
  animation-name: slideIn;
  animation-iteration-count: 1;
  animation-direction: alternate;
  animation-delay: 1s ease-in;
}

@keyframes slideIn {
  from {
    opacity: 0;
    margin-left:-100%;
  }
  
  to {
    opacity: 1;
    margin-left:0%;
  }
}

.bg-grey {
  background-color: rgba(0, 0, 0, 0.05);
}

.v-card.on-hover.theme--dark {
  transition: background-color 0.5s ease;
  background-color: rgba(#FFF, 0.8)
}
</style>